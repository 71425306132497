import Api from "./api";

export default {
  index() {
    return Api().get("/stores/getAllStores");
  },
  indexAllData() {
    return Api().get("/stores/findStoreWithAllData");
  },
  show(id) {
    return Api().get(`/stores/index/${id}`);
  },
  updateStoreInfo(storeInfo) {
    return Api().post("/stores/updateStore", storeInfo);
  },
  insertNewStore(newStore) {
    return Api().post("/stores/insertStore", newStore);
  },
  getStoreDailySpecial(storeId) {
    return Api().get(`/dailySpecial/index/${storeId}`);
  },
  saveStoreDailySpecial(dsContent) {
    return Api().post("/dailySpecial/InsertDailySpecial", dsContent);
  },
  saveStoreHappyHour(happyHourContent) {
    return Api().post("/happyHour/InsertHappyHour", happyHourContent);
  },
  getStoreHappyHour(storeId) {
    return Api().get(`/happyHour/index/${storeId}`);
  },
  deleteStore(storeId) {
    return Api().delete(`/stores/deleteStore/${storeId}`);
  },
  getStoreDetailFromGoogle(storeName) {
    return Api().get(`/GoogleAPI/getStoreDetail/${storeName}`);
  },
  getStoreDetailFromGoogleUsingId(storeId) {
    return Api().get(`/GoogleAPI/getStoreDetailWithID/${storeId}`);
  },
  getCoopStores() {
    return Api().get("/stores/getAllCoopStores");
  },
  getCheckedStores() {
    return Api().get("/stores/getAllCheckedStores");
  },
  uploadImagesTest(data) {
    return Api().post("/photos/getPhotos", data);
  },
  uploadImages(data) {
    return Api().post("/photos/upload", data);
  },
  getStoreImages(store_id) {
    return Api().get(`/photos/index/${store_id}`);
  },
  deleteStoreImage(data) {
    return Api().post("/photos/delete", data);
  },
  reorderStoreImages(data) {
    return Api().post("/photos/reorderImages", data);
  },
  getCheckedStoresWithAllData() {
    return Api().get("/stores/findCheckedStoreWithAllData");
  },
  //Note
  getAllCuisines() {
    return Api().get("/note/index");
  },
  postNewCuisine(cuisine) {
    return Api().post("/note/post", cuisine);
  },
  deleteCuisine(cuisine) {
    return Api().post("/note/deleteCuisine", cuisine);
  },
  updateCuisine(cuisine) {
    return Api().post("/note/updateCuisine", cuisine);
  },
  getAllTag() {
    return Api().get("/note/IndexTags")
  },
  postNewTag(tag) {
    return Api().post("/note/PostTag", tag)
  },
  deleteTag(tag) {
    return Api().post("/note/DeleteTag", tag)
  },
  updateTag(tag) {
    return Api().post("/note/UpdateTag", tag)
  },
  getAllCoupon() {
    return Api().get("/note/IndexCoupons")
  },
  postNewCoupon(coupon) {
    return Api().post("/note/PostCoupon", coupon)
  },
  deleteCoupon(coupon) {
    return Api().post("/note/DeleteCoupon", coupon)
  },
  updateCoupon(coupon) {
    return Api().post("/note/UpdateCoupon", coupon)
  }
};
