import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import EditStoreView from "../views/EditStoreView.vue";
import StoreListView from "../views/StoreListView.vue";
import BusinessDataVue from "@/views/BusinessData.vue";
import MapVue from "../views/Map.vue";
import LoginView from "../views/Login.vue";
import CuisineView from "../views/CuisineTable.vue";
import NewStoreView from "../views/newStore.vue";
import TagView from "../views/TagTable.vue";
import CouponView from "../views/CouponManagement.vue";
import ReviewView from "../views/ReviewView.vue";
import LogView from "../views/LogView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/map",
    name: "Map",
    component: MapVue,
  },
  {
    path:"/logs",
    name:"Logs",
    component:()=>import("../views/LogView.vue"),
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/business",
    name: "BusinessDataVue",
    component: BusinessDataVue,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/admin",
    name: "admin",
    component: StoreListView,
  },
  {
    path: "/editstore/:storeId",
    name: "EditStoreView",
    component: EditStoreView,
  },
  {
    path: "/reviews",
    name: "ReviewView",
    component: ReviewView,
  },
  {
    path: "/newstore/",
    name: "NewStoreView",
    component: NewStoreView,
  },
  {
    path: "/cuisineManagement",
    name: "CuisineManagement",
    component: CuisineView,
  },
  {
    path: "/tagManagement",
    name: "TagManagement",
    component: TagView,
  },
  {
    path: "/couponManagement",
    name: "couponManagement",
    component: CouponView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
