<template>
  <v-container fluid>
    <LogTable :data="all_logs" />
  </v-container>
</template>

<script>
import AdminService from "@/services/AdminService";
import LogTable from "@/components/LogTable.vue";
export default {
  components: {
    LogTable,
  },
  data: () => ({
    all_logs: [],
  }),

  computed: {},

  watch: {},

  async created() {
    const response = await AdminService.getAllLogs();
    console.log(response.data);
    this.all_logs = response.data;
    for (let i = 0; i < this.all_logs.length; i++) {
      if (!this.all_logs[i].date) {
        console.log(this.all_logs[i]);
        console.log(i);
      }
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
