<template>
  <div>
    <v-btn
      color="green"
      class="white--text ml-2"
      @click="$router.push('/couponManagement')"
      >Manage Coupons</v-btn
    >
    <v-btn color="green" class="white--text ml-2" @click="$router.push('/tagManagement')"
      >Manage Tags</v-btn
    >
    <v-btn
      color="green"
      class="white--text ml-2"
      @click="$router.push('/cuisineManagement')"
      >Manage Cuisine</v-btn
    >
    <v-btn color="green" class="white--text ml-2" @click="$router.push('/Reviews')"
      >Reviews</v-btn
    >
    <v-btn color="green" class="white--text ml-2" @click="$router.push('/Logs')"
      >User Log</v-btn
    >
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  computed: {},

  watch: {},

  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
